import React from "react";

export default function Spinner() {
  return (
    <div className=" mt-5 mb-5 text-center">
      <div className="spinner-grow text-info" style={{width: "3rem", height: "3rem" }}role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
