import React from 'react';
import Navbar from "@/components/Nav";
import image from "../../assets/img/error404.png";
import { NavLink } from "react-router-dom";

import "./Page404.scss";

function Page404 () 
{
    return (
        <>
            <Navbar />

            <div className="notFound">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2"></div>
                        <div className="col-lg-8 col-md-8 col-sm-8">

                            <div className="imgWrap">
                                <img src={ image } alt="Cannot find page image" className="__error" />
                            </div>


                            <p className="__errorp">
                                We can't find the page you are looking for, try and go back to the previous page of go to the
                                <NavLink to="/"> home</NavLink> or
                                <NavLink to="/help"> help </NavLink> page for more information
                            </p>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2"></div>

                    </div>

                </div>
            </div>

        </>
    );
}

export default Page404;
