import { UPDATE_NOTIFICATION_STATUS } from "./actionTypes";

const initialState = {
  notification: false
};

const NotificationReducer = (state = initialState, actions) => {
  switch(actions.type) {
    case UPDATE_NOTIFICATION_STATUS: {
      return { ...state, notification: actions.payload.status } ;
    }

    default: 
      return state;
  }
}

export default NotificationReducer;
