import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import CONFIG from "@/config";
import axios from "axios";
import { Provider } from "react-redux";
import { store } from "@/store";
import Spinner from "@/components/Spinner";
import "./style.scss";
import NotFoundPage from "./pages/Page404/Page404";
const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const About = lazy(() => import("./pages/About"));
const Login = lazy(() => import("./pages/Login"));
const Index = lazy(() => import("./pages/index"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Register = lazy(() => import("./pages/Register"));
const Faq = lazy(() => import("./pages/FAQs"));
const Otp = lazy(() => import("./pages/Otp"));

const AppDashboard = lazy(() => import("./pages/Dashboard/index"));

class App extends Component {
  state = {
    font_size: 16,
    app_colour: "#FFF000",
    dark_mode: 0,
    userId: 1,
  };

  mr_15p = {
    margin_right:15,
  }

  changeSettings = (settings) => {
    this.setState({
      dark_mode: settings.dark_mode,
      font_size: settings.font_size,
    });
  };

  componentDidMount() {
    const token = JSON.parse(localStorage.getItem("token"))?.token;

    if (localStorage.getItem("eko-dark")) {
      this.setState({
        dark_mode: JSON.parse(localStorage.getItem("eko-dark")),
      });
    }

    if (localStorage.getItem("eko-fs")) {
      this.setState({
        font_size: JSON.parse(localStorage.getItem("eko-fs")),
      });
    }

    if (token) {
      axios
        .get(`${CONFIG.BASE_URL}/admin/setting/getAll?page=1`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          if (res.data.data.allSettings.length > 0) {
            this.setState({ ...res.data.data.allSettings[0] });
            localStorage.setItem(
              "eko-dark",
              res.data.data.allSettings[0].dark_mode
            );
            localStorage.setItem(
              "eko-fs",
              res.data.data.allSettings[0].font_size
            );
          }
        });
    }
  }

  render() {
    return (
      <div className={this.state.dark_mode ? `darkMode` : null}>
        <Provider store={store}>
          <Suspense fallback={<Spinner />}>
            <Router>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/index" component={Index} />

                <Route path={"/about"} component={About} />
                <Route path={"/login"} component={Login} />
                <Route path={"/contact"} component={Contact} />
                <Route path={"/register"} component={Register} />
                <Route path={"/forgot-password"} component={ForgotPassword} />
                <Route path={"/reset-password"} component={ResetPassword} />
                <Route path={"/faqs"} component={Faq}/>
                <Route path={"/otp"} component={Otp}/>
                {/* <Route path="/dashboard" component={Dashboard} /> */}
                <Route path="/app">
                  {/* <Route path="/dashboard" component={Dashboard} /> */}

                  <AppDashboard
                    settings={this.state}
                    changeSettings={this.changeSettings}
                  />
                </Route>

                {/* <Route path="*">
                  <NotFoundPage />
                </Route> */}
                <Route component={NotFoundPage} />
              </Switch>
            </Router>
          </Suspense>
        </Provider>
      </div>
    );
  }
}

export default App;
