import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import styles from "./nav.module.scss";
import Layout from "../Layout";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <Layout>
      <div className="">
        <div className={styles.nav}>
          <div className="d-none d-lg-flex justify-content-between pl-lg-5 pr-lg-5 ">
            <div>
              <NavLink to={`/`}>
                <img
                  src={require("@/assets/img/lagos-logo.png")}
                  alt="logo"
                  className="__lagos-logo"
                />
                <img
                  src={require("@/assets/img/logo.png")}
                  alt="logo"
                  className="__logo"
                />
              </NavLink>
            </div>
            <div className="row col-4 justify-content-between align-items-center pt-2">
              <NavLink activeClassName="navbar__link--active" to={`/about`}>
                <p>About</p>
              </NavLink>
              <a
                activeClassName="navbar__link--active"
                href="http://eko360.ng/"
                target="_blank"
              >
                <p>Insights</p>
              </a>
              <NavLink activeClassName="navbar__link--active" to={`/contact`}>
                <p>Contact</p>
              </NavLink>
              <NavLink activeClassName="navbar__link--active" to={`/faqs`}>
                <p>FAQs</p>
              </NavLink>
            </div>
            <div className="row justify-content-between ">
              <a
                activeClassName="navbar__link--active"
                href="http://eko360.ng/publications"
                target="_blank"
              >
                <p className="mt-1r">Discover</p>
              </a>
              <Link to={`/login`}>
                <button className="btn btn-white ml-4">Sign In</button>
              </Link>
            </div>
          </div>

          {/* mobile menu */}
          <div className="container mobile d-lg-none">
            <div className="row justify-content-between">
              <Link to={`/`}>
                <img src={require("@/assets/img/logo.png")} alt="logo" />
              </Link>
              {isOpen ? (
                <div className={`${styles.handbuger}`} onClick={toggleMenu}>
                  <img
                    src={require("@/assets/icons/close.svg")}
                    alt="competitive"
                  />
                </div>
              ) : (
                <div className={`${styles.handbuger}`} onClick={toggleMenu}>
                  <img
                    src={require("@/assets/icons/menu.svg")}
                    alt="competitive"
                  />
                </div>
              )}
            </div>
            {isOpen && (
              <div className="">
                <div className=" mt-5">
                  <NavLink to={`/about`}>
                    <p>About Us</p>
                  </NavLink>
                  <a
                    activeClassName="navbar__link--active"
                    href="http://eko360.ng/"
                    target="_blank"
                  >
                    <p>Insights</p>
                  </a>
                  <NavLink to={`/contact`}>
                    <p>Contact</p>
                  </NavLink>
                  <NavLink to={`/help`}>
                    <p>Help</p>
                  </NavLink>
                </div>
                <div className="d-flex justify-content-start ">
                  <Link to={`/app`}>
                    <p className={`pt-2 ${styles.blue2} mr-3`}>Discover</p>
                  </Link>
                  <Link to={`/login`}>
                    <button className="btn btn-white">Sign In</button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Nav;
